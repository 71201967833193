import message from './message';
import i18next from 'i18next';

export const handleAPIError = (res: any, translateKey?: string): any => {
  console.log('Res message:', res.message);
  console.log('Res response:', res.response);

  if (res.status_message == 'success') {
    message.success(i18next.t(translateKey));
  } else if (res.response) {
    const status = res.response.status;
    const statusMessage = res.response.data?.status_message;
    if (status >= 500) {
      message.error(i18next.t('Error.generalSoftwareFailure'));
    } else if (status >= 300) {
      message.error(`${i18next.t(translateKey)}: ${statusMessage}`);
    } else if (status >= 200) {
      message.warning(`${i18next.t(translateKey)}: ${statusMessage}`);
    }
  } else {
    message.error(`${i18next.t(translateKey)}: ${i18next.t('Error.unableRetrieveDetail')}`);
  }
};
