import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { authLoginAction, setUser } from '@/redux/actions'; // Import userActions
import { authLogin, TAuthLoginResponse } from '@/services/api';
import Helpers from '@/services/helpers';
import message from '@/utils/message';
import { handleAPIError } from '@/utils/handle-api-error';
import { TUserInfo } from '@/services/api/auth/get-user-info';

// FUNCTION
interface IAuthLoginResponse {
  data: {
    access_token: string;
    refresh_token: string;
    user: TUserInfo; // Replace 'any' with the actual type of your user data
    // other fields
  };
}

export function* authLoginSaga(action: ActionType<typeof authLoginAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(authLogin, materials);
    const authLoginResponse: IAuthLoginResponse = response as IAuthLoginResponse;
    Helpers.storeAccessToken(authLoginResponse?.data.access_token);
    Helpers.storeRefreshToken(authLoginResponse?.data.refresh_token);
    Helpers.storeUserInfo(authLoginResponse?.data.user as TUserInfo);
    // Dispatch a Redux action to store the user data
    yield put(authLoginAction.success(authLoginResponse));
    successCallback?.(authLoginResponse);
  } catch (err) {
    yield put(authLoginAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.loginEmailError');
  }
}
